.Layout {
    background-color: #021e25;
    min-height: 100%;
    padding: 0;
    margin: 0 auto;

    nav {
        display: none;
    }
}
