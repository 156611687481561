.Home {
    text-align: center;
    min-height: 100%;
    margin: 0 auto;
    padding-top: 10vh;
    padding-bottom: 20vh;

    @media screen and (max-width: 1024px) {
        padding-top: 3vh;
        padding-bottom: 6vh;
    }

    .Home-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16pt;
        color: white;

        .App-logo {
            height: 4vmin;
            max-height: 3vh;
            pointer-events: none;
        }
    }
}
