.Game-output {
    .Game-output-message-wrapper {
        padding-bottom: 10px;
        &.system-message {
            text-align: left;
            &.message-type-center {
                text-align: center;
                padding-top: 30px;
                padding-bottom: 60px;
            }
        }
        &.user-message {
            text-align: right;
            &.message-type-center {
                text-align: center;
            }
        }
        .Game-output-message {
            display: inline-block;
            padding: 6px 12px;
            border-radius: 12px;
            font-size: 16pt;

            &.message-type-dialogue {
                &.system-message {
                    background-color: #3b7584;
                }
                &.user-message {
                    background-color: #6964a4;
                }
            }

            &.message-type-narrative-evil {
                &.system-message {
                    color: #9d3838;
                    font-style: italic;
                }
            }

            &.message-type-center {
                background-color: #9e9ea4;
                &.user-message {
                    background-color: #64a478;
                }
            }
        }
    }
}
