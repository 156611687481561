.Game-input {
    border-top: solid 1px #3d464f;
    padding-top: 6px;

    input,
    button {
        display: inline-block;
        padding: 6px;
        font-size: 16pt;
        border: 0;
    }
    input {
        width: 80%;
        background-color: #02242c;
        color: white;
        padding-left: 12px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
    button {
        width: 20%;
        background-color: #3b7584;
        color: white;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    @media screen and (max-width: 500px) {
        input {
            width: 65%;
        }
        button {
            width: 35%;
        }
    }
}
