.NotFound {
    text-align: center;
    padding: 5rem 0;

    .NotFound-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;

        .App-logo {
            height: 4vmin;
            max-height: 3vh;
            pointer-events: none;
        }
    }
}
