.App-links {
    padding-bottom: 1vh;
    line-height: 10px;
    margin-bottom: 16px;

    .App-link {
        font-size: 12pt;
        color: #61dafb;
        text-decoration: none;
    }
}
